<template>
  <div>
      <div class="login-form login-signin">
          <div class="text-center mb-10 mb-lg-20">
              <h3 class="font-size-h1">Verifica indirizzo e-mail</h3>
          </div>

          <div v-if="verified === true">
              <div role="alert" class="alert alert-success">
                  <div class="alert-text">
                      Il tuo indirizzo e-mail è stato verificato con successo
                  </div>
              </div>

              <button
                  type="button"
                  v-on:click.prevent="$router.push('/login')"
                  class="btn btn-primary btn-block font-weight-bold font-size-3"
              >
                  Effettua il Login
              </button>
          </div>

          <div v-if="verified === false">
              <div role="alert" class="alert alert-danger">
                  <div class="alert-text">
                      {{verified_error_message}}
                  </div>
              </div>

              <button
                  type="button"
                  v-on:click.prevent="$router.push('/login')"
                  class="btn btn-light-primary btn-block font-weight-bold font-size-3"
              >
                  Torna alla pagina di Login
              </button>
          </div>
      </div>
  </div>
</template>

<style lang="scss" scoped>
 .spinner.spinner-right {
     padding-right: 3.5rem !important;
}
</style>

<script>
 import ApiService from "@/core/services/api.service";

 export default {
     name: "verify",
     data() {
         return {
             email_verify_token: '',

             verified: null,
             verified_error_message: '',
         };
     },
     created() {
         this.email_verify_token = this.$route.params.token;

         let url = '/auth/verify/' + encodeURI(this.email_verify_token);
         ApiService.query(url)
                   .then((response) => {
                       if (response.data.status === 'OK') {
                           this.verified = true;
                           this.verified_error_message = response.data.verified_error_message;
                       }

                       if (response.data.status === 'KO') {
                           this.verified = false;
                           this.verified_error_message = response.data.verified_error_message;
                       }
                   })
                   .catch((error) => {
                       console.log(error);
                   })
     },
     methods: {
     },
     computed: {
     }
 };
</script>
